import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, keywords, title, url, imgurl }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
          }
        }
      }
    `,
  )
  const siteTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords
  const metaUrl = url ? `${site.siteMetadata.siteUrl}/${url}` : site.siteMetadata.siteUrl
  const metaImgUrl = imgurl ? `${site.siteMetadata.siteUrl}${imgurl}` : `${site.siteMetadata.siteUrl}/icons/icon-512x512.png`
  return (
    <Helmet
      htmlAttributes={{
        lang: 'tr',
      }}
      title={siteTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { name: 'robots', content: 'index,follow' },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },

        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { property: 'twitter:url', content: metaUrl },
        { property: 'twitter:image', content: metaImgUrl },
        // {property:"twitter:site", content="1"},

        { name: 'dc.language', content: 'tr' },
        { name: 'dc.language.iso', content: 'tr' },
        { name: 'dc.publisher', content: 'Erez Kaynak' },
        { name: 'dc.title', content: siteTitle },
        { name: 'dc.description', content: metaDescription },
        { name: 'dc.date.issued', content: '2019-12-15' },

        { property: 'og:url', content: metaUrl },
        { property: 'og:title', content: siteTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: metaImgUrl },
        { property: 'og:image:width', content: '500' },
        { property: 'og:image:type', content: 'image/jpeg' },
        { property: 'og:site_name', content: 'Erez Kaynak' },
        { property: 'og:locale', content: 'tr' },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO
